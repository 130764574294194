import React from 'react'

const MaticIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = ({
  className,
  width = 20,
  height = 21,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 253 221"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <svg
      width="253"
      height="221"
      viewBox="0 0 253 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2162_33137)">
        <path
          d="M191.068 67.1482C186.456 64.5149 180.526 64.5149 175.255 67.1482L138.359 88.8726L113.323 102.697L77.0859 124.422C72.474 127.055 66.5443 127.055 61.2734 124.422L32.9427 107.305C28.3307 104.672 25.0365 99.4056 25.0365 93.4808V60.5651C25.0365 55.2985 27.6719 50.032 32.9427 46.7404L61.2734 30.2826C65.8854 27.6493 71.8151 27.6493 77.0859 30.2826L105.417 47.3988C110.029 50.032 113.323 55.2985 113.323 61.2234V82.9478L138.359 68.4648V46.0821C138.359 40.8156 135.724 35.5491 130.453 32.2575L77.7448 1.31674C73.1328 -1.31652 67.2031 -1.31652 61.9323 1.31674L7.90625 32.9158C2.63542 35.5491 0 40.8156 0 46.0821V107.964C0 113.23 2.63542 118.497 7.90625 121.788L61.2734 152.729C65.8854 155.362 71.8151 155.362 77.0859 152.729L113.323 131.663L138.359 117.18L174.596 96.114C179.208 93.4808 185.138 93.4808 190.409 96.114L218.74 112.572C223.352 115.205 226.646 120.472 226.646 126.397V159.312C226.646 164.579 224.01 169.845 218.74 173.137L191.068 189.595C186.456 192.228 180.526 192.228 175.255 189.595L146.924 173.137C142.312 170.504 139.018 165.237 139.018 159.312V138.246L113.982 152.729V174.453C113.982 179.72 116.617 184.987 121.888 188.278L175.255 219.219C179.867 221.852 185.797 221.852 191.068 219.219L244.435 188.278C249.047 185.645 252.341 180.378 252.341 174.453V111.914C252.341 106.647 249.706 101.381 244.435 98.089L191.068 67.1482Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2162_33137">
          <rect width="253" height="220.535" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </svg>
)
export default MaticIcon
