import Tooltip from '../Tooltip'

type ICurrency = 'BRL' | 'USD'

type Props = {
  tooltipId: number | string
  currency: ICurrency
}

type CurrencyProps = {
  currency: ICurrency
}

const Content: React.FC<CurrencyProps> = ({ currency }) => {
  return <p>{currency === 'BRL' ? 'R$' : '$'}</p>
}

const Currency: React.FC<Props> = ({ tooltipId, currency }) => {
  return (
    <div className=" text-theme-primary text-xs font-bold uppercase mt-0.5">
      <Tooltip
        id={`header-${tooltipId}`}
        hoverText={currency}
        content={<Content currency={currency} />}
        icon={false}
      />
    </div>
  )
}

export default Currency
