import Image from 'next/image'
import React from 'react'
import ChainIcon from '../../../assets/icons/ChainIcon'
import NoneIcon from '../../../assets/images/none-icon.svg'

type Props = {
  currency: string
  currencyIcon?: string
  width: number | string
  height: number | string
  className?: string
}

const BlockchainCurrency: React.FC<Props & React.SVGAttributes<HTMLOrSVGElement>> = ({
  currency,
  currencyIcon,
  className,
  width,
  height,
  ...rest
}) => {
  return (
    <>
      {['BMW-ETH', 'ETH', 'MATIC', 'XLM'].includes(currency) && !currencyIcon ? (
        <ChainIcon
          blockchainCurrency={currency}
          width={width}
          height={height}
          className={className}
          {...rest}
        />
      ) : (
        <div className={className}>
          <Image
            src={currencyIcon || NoneIcon}
            alt={currency}
            width={width || 18}
            height={height || 18}
          />
        </div>
      )}
    </>
  )
}
export default BlockchainCurrency
