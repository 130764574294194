import React from 'react'

const EthIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = ({
  className,
  width = 20,
  height = 21,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3604_30217)">
        <path
          d="M10.0376 0.5L9.90466 0.955635V14.1771L10.0376 14.3109L16.1218 10.6832L10.0376 0.5Z"
          fill="currentColor"
        />
        <path d="M10.0376 0.5L3.95337 10.6832L10.0376 14.311V7.89367V0.5Z" fill="currentColor" />
        <path
          d="M10.0377 15.4736L9.96277 15.5656V20.2754L10.0377 20.496L16.1256 11.8477L10.0377 15.4736Z"
          fill="currentColor"
        />
        <path d="M10.0376 20.4961V15.4736L3.95337 11.8477L10.0376 20.4961Z" fill="currentColor" />
        <path d="M10.0376 14.3117L16.1217 10.6841L10.0376 7.89453V14.3117Z" fill="currentColor" />
        <path d="M3.95337 10.6841L10.0375 14.3118V7.89453L3.95337 10.6841Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_3604_30217">
          <rect width="12.1739" height="20" fill="white" transform="translate(3.95251 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </svg>
)
export default EthIcon
