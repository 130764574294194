import BlockchainCurrency from '../../Widgets/BlockchainCurrency'
import Tooltip from '../Tooltip'

export const BMW_BLOCKCHAIN_DATA = {
  currency: 'BMW ETH',
  currencyIcon: '/images/coin-placeholder.png',
}

type Props = {
  tooltipId: number | string
}

const BmwCurrency: React.FC<Props> = ({ tooltipId }) => {
  return (
    <div className="mt-1">
      <Tooltip
        id={`header-${tooltipId}`}
        hoverText={BMW_BLOCKCHAIN_DATA.currency}
        icon={
          <BlockchainCurrency
            currency={BMW_BLOCKCHAIN_DATA.currency}
            currencyIcon={BMW_BLOCKCHAIN_DATA.currencyIcon}
            height={21}
            width={20}
          />
        }
      />
    </div>
  )
}

export default BmwCurrency
