import React from 'react'
import EthIcon from '../EthIcon'
import LumenIcon from '../LumenIcon'
import MaticIcon from '../MaticIcon'

type Props = {
  blockchainCurrency?: string
}

const ChainIcon: React.FC<Props & React.SVGAttributes<HTMLOrSVGElement>> = ({
  blockchainCurrency,
  ...rest
}) => {
  switch (blockchainCurrency) {
    case 'MATIC':
      return <MaticIcon {...rest} />
    case 'XLM':
      return <LumenIcon {...rest} />
    default:
      return <EthIcon {...rest} />
  }
}

export default ChainIcon
